import api from "utils/api";
import { STRAPI_CONTENT_URLS } from "common/constants";

class ContentLoader {
  async loadContent(contentKey: keyof typeof STRAPI_CONTENT_URLS) {
    const url = STRAPI_CONTENT_URLS[contentKey];

    if (!url) {
      throw new Error(
        `Content key ${contentKey} not found in STRAPI_CONTENT_URLS.`
      );
    }

    try {
      const response = await api.get(url);

      return response.data;
    } catch (error) {
      console.error(`Error loading content for ${contentKey}:`, error);
      throw error;
    }
  }
}

export default ContentLoader;
