import React, { useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";

import { useStrapiContext } from "context/StrapiContext";
import Footer from "common/components/Footer";
import StickyNavbar from "common/components/StickyNavbar";

import LoadingOverlay from "common/components/LoadingOverlay";

import "./style.css";

let About = () => {
  const { content, fetchContent } = useStrapiContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContent([
      "instagramLogo",
      "contactInfo",
      "companyLogo",
      "serviceAreas",
      "about",
    ]);
  }, []);

  let logo =
    content?.companyLogo?.data?.attributes?.logo1?.data?.attributes?.url;
  let p1 = content?.about?.data?.attributes?.Paragragh_1;
  let p2 = content?.about?.data?.attributes?.Paragraph_2;

  let loaded =
    content.instagramLogo &&
    content.companyLogo &&
    content.contactInfo &&
    content.serviceAreas &&
    content.about;

  return loaded ? (
    <>
      <StickyNavbar />
      <div className="gradient-background">
        <Container
          fluid
          id="about-section"
          className="nav-margin d-flex flex-column justify-content-between p-0"
        >
          <Container
            fluid
            id="about-stack"
            className="px-3 d-flex flex-column justify-content-center align-items-center mb-5"
          >
            <Container fluid className="title-container p-0">
              <h1 className="title display-3 fw-bold">About Us</h1>
            </Container>

            <div
              id="about-description-container"
              className="glass-card d-flex flex-column justify-content-center align-items-center p-4"
            >
              <p
                className={`about-description m-0 fw-light text-white w-100 ${
                  isMobileOnly ? "fs-6" : "fs-5"
                } `}
              >
                {p1}
              </p>

              <Image
                id="about-logo"
                src={logo}
                height="100"
                className="m-2"
                loading="lazy"
              />

              <p
                className={`about-description fw-light text-white w-100 ${
                  isMobileOnly ? "fs-6" : "fs-5"
                } `}
              >
                {p2}
              </p>
            </div>
          </Container>
          <Footer />
        </Container>
      </div>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default About;
