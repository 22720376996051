export enum SCREEN_ORIENTATION {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
}

export enum STRAPI_CONTENT_URLS {
  homePage = "/home-page?populate[home_backgrounds][populate]=*&populate[recent_designs][populate]=*",
  about = "/about-us-page?populate=*",
  clients = "/clients-page?populate[Clients][populate]=*",
  clientsPage = "/clients-page?populate=*",
  servicesPage = "/services-page?populate[Services][populate]=*",
  companyLogo = "/company-logo?populate=*",
  contactInfo = "/contact-info?populate=*",
  instagramLogo = "/icons/1?populate=*",
  serviceAreas = "/service-areas?populate=*",
}
