import React, { useState, createContext, useContext, ReactNode } from "react";
import ImageModal from "../../common/components/ImageModal";

interface ModalContextType {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalData: any[];
  setModalData: React.Dispatch<React.SetStateAction<any[]>>;
  activeItem: string;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  clearModalData: () => void;
}

interface Props {
  children: ReactNode;
}

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined
);

export const ModalContextProvider = ({ children }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentActiveItem, setCurrentActiveItem] = useState<string>("");
  const [imageModalData, setImageModalData] = useState<any[]>([]);

  const clearModalData = () => {
    setCurrentActiveItem("");
    setImageModalData([]);
  };

  const contextValue: ModalContextType = {
    showModal: isModalVisible,
    setShowModal: setIsModalVisible,
    modalData: imageModalData,
    setModalData: setImageModalData,
    activeItem: currentActiveItem,
    setActiveItem: setCurrentActiveItem,
    clearModalData,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {isModalVisible ? <ImageModal /> : children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      "useModalContext must be used within a ModalContextProvider"
    );
  }
  return context;
};
