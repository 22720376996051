import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Marquee from "react-fast-marquee";
import { Carousel, Container, Image } from "react-bootstrap";

import { useStrapiContext } from "context/StrapiContext";

import RecentDesigns from "pages/RecentDesigns";

import StickyNavbar from "common/components/StickyNavbar";

import Footer from "common/components/Footer";
import LoadingOverlay from "common/components/LoadingOverlay";
import "./style.css";

let Landing = () => {
  const { content, fetchContent } = useStrapiContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContent([
      "instagramLogo",
      "companyLogo",
      "contactInfo",
      "companyLogo",
      "serviceAreas",
      "homePage",
      "clients",
    ]);
  }, []);

  let pageContent = content.homePage;

  let introMsg = pageContent?.data?.attributes?.Welcome_Message;
  let introMsg2 = pageContent?.data?.attributes?.Welcome_Message2;
  let introTitle = pageContent?.data?.attributes?.Welcome_Title;

  let bgImages = pageContent?.data?.attributes?.home_backgrounds?.data;
  let recentDesigns = pageContent?.data?.attributes?.recent_designs?.data;

  const clients = content?.clients?.data?.attributes?.Clients?.data?.map(
    (item: any, index: number) => {
      return item.attributes;
    }
  );

  const transformedData = clients
    ? clients.map((item: any) => {
        let imageAttributes = item.Logo.data.attributes;
        return {
          image: imageAttributes.formats?.small?.url || imageAttributes.url,
          text:
            imageAttributes.alternativeText || imageAttributes.name || "Image",
          id: imageAttributes.id,
        };
      })
    : [];

  const renderIntroTitle = () => {
    return (
      <Container className="intro-info d-flex flex-column justify-content-center align-items-center text-center">
        <div className="text-white heavy-shadow-text">
          <h1 className="title display-3 fw-bold">{introTitle}</h1>
          <h3 className="fs-5 fs-md-3">{introMsg}</h3>
        </div>
        <Link to="/About" className="glassbtn shadow">
          <span>Learn More</span>
        </Link>
      </Container>
    );
  };

  let loaded =
    content.instagramLogo &&
    content.companyLogo &&
    content.contactInfo &&
    content.serviceAreas &&
    pageContent;

  return (
    <>
      {loaded ? (
        <>
          <StickyNavbar />
          <div className="gradient-background">
            <section id="intro-section" className="d-flex flex-column">
              <Carousel
                className="landing-carousel position-absolute"
                indicators={false}
                controls={false}
                pause={false}
                interval={5000}
                fade
              >
                {bgImages
                  ?.sort(() => Math.random() - 0.5)
                  .map((data: any, index: any) => {
                    let url = data.attributes.image.data.attributes.url;
                    return (
                      <Carousel.Item
                        className="landing-carousel-item"
                        key={url}
                      >
                        <div className="carousel-item-container">
                          <Image
                            className="landing-carousel-image"
                            src={url}
                            alt={`Slide ${index}`}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  })}
              </Carousel>
              <div className="intro-container d-flex flex-column vh-100">
                {renderIntroTitle()}
                <div>
                  <div
                    className={`d-flex flex-column justify-content-start align-items-center`}
                  >
                    <Container className="d-flex flex-column justify-content-center align-items-stretch fade-x">
                      <Link to="/Clients">
                        <Marquee>
                          {!!transformedData &&
                            transformedData?.map((item: any, index: number) => {
                              return (
                                <div
                                  key={item.id + item.text}
                                  className="clients-marquee-item-container"
                                >
                                  <img
                                    src={item.image}
                                    alt={item.text}
                                    loading="lazy"
                                    width="500"
                                    height="400"
                                    className="clients-marquee-item"
                                  />
                                </div>
                              );
                            })}
                        </Marquee>
                      </Link>
                    </Container>
                  </div>
                  <h4 className="display-6 fs-4 text-white">
                    {introMsg2}
                  </h4>
                </div>
              </div>
              <RecentDesigns content={recentDesigns} />
              <div className="gap-filler"></div>
              <Footer />
            </section>
          </div>
        </>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};

export default Landing;
