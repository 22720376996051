import React, { useEffect } from "react";
import { Container, Image, Card, CardGroup } from "react-bootstrap";
import { isMobileOnly, isMobile } from "react-device-detect";
import { useModalContext } from "../../context/ModalContext";
import { useStrapiContext } from "context/StrapiContext";
import Footer from "common/components/Footer";
import StickyNavbar from "common/components/StickyNavbar";
import LoadingOverlay from "common/components/LoadingOverlay";

import "./style.css";

let Clients = () => {
  const { setShowModal, setModalData, setActiveItem } = useModalContext();

  const { content, fetchContent } = useStrapiContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContent(["instagramLogo", "companyLogo", "contactInfo", "serviceAreas", "clients"]);
  }, []);

  const desc = content?.clients?.data?.attributes?.Description;
  const clients = content?.clients?.data?.attributes?.Clients?.data?.map(
    (item: any, index: number) => {
      return item.attributes;
    }
  );

  let loaded =
    content.instagramLogo &&
    content.companyLogo &&
    content.contactInfo &&
    content.serviceAreas &&
    content.clients;

  return loaded ? (
    <>
      <StickyNavbar />
      <div className="gradient-background">
        <div
          id="clients-section"
          className="nav-margin d-flex flex-column justify-content-between"
        >
          <Container
            fluid
            id="clients-section-inner"
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Container fluid className="title-container">
              <h1 className="title display-3 fw-bold">Clients</h1>
            </Container>

            <Container
              id="clients-description-container"
              className="glass-card d-flex flex-column justify-content-center align-items-center p-4 mt-1 gap-4"
            >
              <p
                className={`fw-light text-white ${
                  isMobileOnly ? "fs-6" : "fs-5"
                } mb-0`}
              >
                {desc}
              </p>
              <small className="text-light mt-3">
                {isMobile ? "Tap" : "Click"} on a client to view our work
              </small>
              <div className="d-flex flex-row flex-wrap justify-content-center align-items-stretch p-0">
                <CardGroup className="d-flex flex-row flex-wrap justify-content-center">
                  {clients.map((client: any, i: number) => {
                    return (
                      <Card
                        key={client?.Name + i}
                        className={`${
                          client?.Images?.data?.length > 0 &&
                          "clickable hover-scale"
                        } client-logo p-2 mx-0 mx-sm-3 d-flex flex-column justify-content-center align-items-center bg-transparent`}
                        onClick={async () => {
                          if (client?.Images?.data?.length > 0) {
                            await setActiveItem(
                              client?.Images?.data?.[0]?.attributes?.url
                            );
                            await setModalData([
                              ...client?.Images?.data?.map(
                                (item: any, index: number) => {
                                  return item?.attributes?.url;
                                }
                              ),
                            ]);
                            setShowModal(true);
                          }
                        }}
                      >
                        <Image
                          src={client?.Logo?.data?.attributes?.url}
                          className="client-logo_image"
                        />
                      </Card>
                    );
                  })}
                </CardGroup>
              </div>
            </Container>
          </Container>
          <div className="gap-filler"></div>
          <Footer />
        </div>
      </div>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default Clients;
