import React, { useEffect } from "react";
import { Row, Col, Container, Carousel, Image } from "react-bootstrap";
import { isMobileOnly } from "react-device-detect";
import { useStrapiContext } from "context/StrapiContext";
import Footer from "common/components/Footer";
import StickyNavbar from "common/components/StickyNavbar";
import LoadingOverlay from "common/components/LoadingOverlay";

import "./style.css";
import { useModalContext } from "../../context/ModalContext";

let Services = () => {
  const { content, fetchContent } = useStrapiContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchContent([
      "instagramLogo",
      "companyLogo",
      "contactInfo",
      "companyLogo",
      "serviceAreas",
      "servicesPage",
    ]);
  }, []);

  let pageContent = content?.servicesPage;

  let services = pageContent?.data?.attributes?.Services?.data?.map(
    (item: any, index: number) => {
      return item?.attributes;
    }
  );

  let { setShowModal, setModalData, setActiveItem } = useModalContext();

  let loaded =
    content.instagramLogo &&
    content.companyLogo &&
    content.contactInfo &&
    content.serviceAreas &&
    pageContent;

  return loaded ? (
    <>
      <StickyNavbar />
      <div className="gradient-background">
        <Container
          id="services-section"
          className="p-lg-0 p-0 d-flex flex-column justify-content-between align-items-center nav-margin"
          fluid
        >
          <Row className="pt-1">
            <Container fluid className="title-container">
              <h1 className="title display-3 fw-bold">Services</h1>
            </Container>
          </Row>
          <Row className="mx-0 d-flex flex-column justify-content-center align-items-center px-3">
            {services &&
              services?.map((service: any, index: number) => {
                return (
                  <Col
                    key={`${service.title}${index}`}
                    xs="12"
                    lg="10"
                    className="service-card glass-card p-4 mb-3"
                  >
                    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                      <div className="w-100 flex-grow-1">
                        <Carousel indicators={false} pause={"hover"} fade>
                          {service?.Images?.data?.map(
                            (image: any, i: number) => {
                              return (
                                <Carousel.Item key={image?.attributes?.url + i}>
                                  <Image
                                    className="service-card-image clickable h-100 w-100"
                                    src={image?.attributes?.url}
                                    loading="lazy"
                                    width="500"
                                    height="400"
                                    onClick={async () => {
                                      await setModalData([
                                        ...service?.Images?.data?.map(
                                          (item: any, index: number) => {
                                            return item?.attributes?.url;
                                          }
                                        ),
                                      ]);
                                      await setActiveItem(
                                        image?.attributes?.url
                                      );
                                      setShowModal(true);
                                    }}
                                  />
                                </Carousel.Item>
                              );
                            }
                          )}
                        </Carousel>
                      </div>
                      <div className="w-100 flex-grow-1 p-md-3">
                        <h2 className="text-white fw-light text-start mt-3 mt-md-0">
                          {service?.Title}
                        </h2>
                        <p
                          className={`fw-light text-white ${
                            isMobileOnly ? "fs-6" : "fs-5"
                          } text-start service-card-text`}
                        >
                          {service?.Description}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <Footer />
        </Container>
      </div>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default Services;
