import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import { X, Images, ChevronDoubleLeft } from "react-bootstrap-icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { isMobileOnly } from "react-device-detect";

import Controls from "./components/Controls";
import { useScreenOrientation } from "../../hooks/ScreenOrientation";
import { useModalContext } from "../../../context/ModalContext";
import { SCREEN_ORIENTATION } from "../../constants";

import "./style.css";

let currentLocation: string;

const Index = () => {
  let loc = useLocation();

  const so = useScreenOrientation();

  const { setShowModal, modalData, activeItem, setActiveItem, clearModalData } =
    useModalContext();

  const [showFooter, setShowFooter] = useState<boolean>(true);

  useEffect(() => {
    currentLocation = loc.key;
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentLocation !== loc.key) {
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc]);

  const renderMenuButton = () => {
    return (
      <span
        className="m-1 text-white clickable d-flex flex-row justify-content-center fs-3 gap-1"
        onClick={() => {
          setShowFooter(!showFooter);
        }}
      >
        {showFooter && so === SCREEN_ORIENTATION.PORTRAIT ? (
          renderXButton()
        ) : (
          <Images />
        )}
      </span>
    );
  };

  const renderBackButton = () => {
    return (
      <span
        className="m-2 text-white clickable d-flex flex-row justify-content-center align-items-center fs-3 close-btn"
        onClick={() => {
          setShowModal(false);
          clearModalData();
        }}
      >
        <ChevronDoubleLeft />
        <h5 className="m-0 fw-lighter">Back</h5>
      </span>
    );
  };

  const renderXButton = () => {
    return (
      <X
        size={25}
        className="bg-black rounded-1 border border-secondary text-white clickable"
        onClick={() => {
          setShowFooter(!showFooter);
        }}
      />
    );
  };

  const renderPortraitMenu = () => {
    return (
      <div
        className={`${
          showFooter &&
          "img-modal__footer-container border-0 border-bottom border-dark shadow"
        }`}
      >
        <div
          className={`glass d-flex flex-row-reverse justify-content-between align-items-center border-bottom border-dark ${
            isMobileOnly && "py-2"
          }`}
        >
          {renderMenuButton()}

          {renderBackButton()}
        </div>
        <div
          id="img-modal__footer"
          className={` border-dark border-bottom ${
            showFooter ? "d-flex flex-column" : "d-none"
          }`}
        >
          <div
            id="img-modal__footer-innner"
            className={`d-flex flex-row gap-3 m-2 p-2`}
          >
            {!!modalData && modalData.length > 0 ? (
              modalData.map((item: any, index: number) => {
                return (
                  <div
                    key={item + index}
                    className="img-modal__footer__img-box clickable rounded shadow-lg my-auto"
                    onClick={async () => {
                      await setActiveItem(modalData[index]);
                    }}
                  >
                    <Image
                      src={item}
                      loading="lazy"
                      className="img-modal__footer__img rounded clickable"
                    />
                  </div>
                );
              })
            ) : (
              <Container
                className="d-flex flex-row justify-content-center align-items-center"
                fluid
              >
                <h4 className="text-light fw-light">No Content</h4>
              </Container>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderLandscapeMenu = () => {
    return (
      <div
        id="img-modal__footer"
        className={`img-modal__footer-container position-absolute top-0 end-0 border-dark border-start p-1 h-100`}
      >
        <span className="d-flex flex-row justify-content-start align-items-center pb-2 border-bottom border-dark ">
          {renderXButton()}
        </span>
        <div
          id="img-modal__footer-innner"
          className={`d-flex flex-column gap-3 p-2`}
        >
          {!!modalData && modalData.length > 0 ? (
            modalData.map((img: any, index: number) => {
              return (
                <div
                  className="img-modal__footer__img-box rounded shadow-lg my-auto"
                  onClick={async () => {
                    await setActiveItem(modalData[index]);
                  }}
                  key={`ls-md-${index}`}
                >
                  <Image
                    src={img}
                    className="img-modal__footer__img rounded clickable"
                    loading="lazy"
                  />
                </div>
              );
            })
          ) : (
            <Container
              className="h-100 d-flex flex-row justify-content-center align-items-center"
              fluid
            >
              <h4 className="text-light fw-light">No Content</h4>
            </Container>
          )}
        </div>
      </div>
    );
  };

  const renderActiveItem = (activeItem: any) => {
    return (
      <TransformWrapper>
        {!isMobileOnly && (
          <div className="position-absolute start-0 bottom-0 m-2 tools-container ">
            <Controls />
          </div>
        )}
        <TransformComponent
          wrapperClass={`vw-100 vh-100 `}
          contentClass={`vw-100 vh-100 d-flex justify-content-center align-items-center`}
        >
          <Image src={activeItem || ""} id="active-item__img" />
        </TransformComponent>
      </TransformWrapper>
    );
  };

  const renderLandscapeView = () => {
    return (
      <Container
        id="img-modal__container"
        className={`p-0 gradient-background`}
        fluid
      >
        <span className="position-absolute top-0 start-0 close-btn">
          {renderBackButton()}
        </span>

        {!showFooter && (
          <span className="position-absolute top-0 end-0 menu-btn">
            {renderMenuButton()}
          </span>
        )}

        <Container
          id="img-modal__active-item__container"
          className={`p-0 m-0 d-flex flex-row justify-content-center align-items-center ${
            showFooter && "position-relative"
          }`}
          fluid
        >
          {renderActiveItem(activeItem)}
        </Container>

        {showFooter && renderLandscapeMenu()}
      </Container>
    );
  };

  const renderPortraitView = () => {
    return (
      <Container
        id="img-modal__container"
        className={`p-0 d-flex flex-column justify-content-center align-items-center gradient-background`}
        fluid
      >
        <div className="position-absolute fixed-top">
          {renderPortraitMenu()}
        </div>
        <Container
          id="img-modal__active-item__container"
          className={`p-0 m-0 d-flex flex-row justify-content-center align-items-center h-100 w-100${
            showFooter && "position-relative"
          }`}
          fluid
        >
          {renderActiveItem(activeItem)}
        </Container>
      </Container>
    );
  };

  return so === SCREEN_ORIENTATION.LANDSCAPE
    ? renderLandscapeView()
    : renderPortraitView();
};

export default Index;
