import React from "react";
import { Carousel, Container } from "react-bootstrap";
import { useModalContext } from "context/ModalContext";
import "./style.css";

const RecentDesigns = (props: any) => {
  const { setModalData, setShowModal, setActiveItem } = useModalContext();

  const recentDesigns = props.content || [];

  const transformedData = recentDesigns?.map((item: any) => {
    const imageAttributes = item.attributes.image?.data?.attributes || {};
    return {
      image: imageAttributes.formats?.large?.url || imageAttributes.url,
      text: imageAttributes.alternativeText || imageAttributes.name || "Image",
      id: item.id,
    };
  });

  return (
    <Container className="mt-5">
      <h1 className="text-light">Recent Designs</h1>
      <Carousel>
        {transformedData.map((data: any) => (
          <Carousel.Item key={data.id}>
            <img
              className="rd-carousel-item clickable"
              src={data.image}
              alt={data.text}
              onClick={async () => {
                await setModalData([
                  ...transformedData?.map((item: any, index: number) => {
                    return item?.image;
                  }),
                ]);
                await setActiveItem(data?.image);
                setShowModal(true);
              }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default RecentDesigns;
