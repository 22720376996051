import { STRAPI_CONTENT_URLS } from "common/constants";
import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import ContentLoader from "utils/ContentLoader";

// Define the structure for site content
export interface SiteContent {
  [key: string]: Record<string, any>;
}

// Extend the context type to include a fetch function
interface StrapiContextType {
  content: SiteContent;
  fetchContent: (keys: (keyof typeof STRAPI_CONTENT_URLS)[]) => Promise<void>;
}

interface Props {
  children?: ReactNode;
}

// Create the context with an initial undefined value
export const StrapiContext = createContext<StrapiContextType | undefined>(
  undefined
);

const contentLoader = new ContentLoader();

const StrapiContextProvider = ({ children }: Props) => {
  const [content, setContent] = useState<SiteContent>({});

  const fetchContent = useCallback(
    async (keys: (keyof typeof STRAPI_CONTENT_URLS)[]) => {
      const missingKeys = keys.filter((key) => !content[key]);

      if (missingKeys.length === 0) {
        return; // All requested content is already loaded
      }

      try {
        const promises = missingKeys.map(async (key) => {
          const data = await contentLoader.loadContent(key);
          return { key, data };
        });

        const results = await Promise.all(promises);

        setContent((prevContent) => {
          const updatedContent = { ...prevContent };
          results.forEach(({ key, data }) => {
            updatedContent[key] = data;
          });
          return updatedContent;
        });
      } catch (error) {
        console.error("Error loading content:", error);
      }
    },
    [content]
  );

  const contextValue: StrapiContextType = {
    content,
    fetchContent,
  };

  return (
    <StrapiContext.Provider value={contextValue}>
      {children}
    </StrapiContext.Provider>
  );
};

// Custom hook to use the context
export const useStrapiContext = () => {
  const context = useContext(StrapiContext);
  if (!context) {
    throw new Error(
      "useStrapiContext must be used within a StrapiContextProvider"
    );
  }
  return context;
};

// Explicitly export StrapiContextProvider
export { StrapiContextProvider };
