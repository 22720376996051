import React from "react";
import { DotLoader } from "react-spinners";
import "./style.css";

function index() {
  return (
    <div
      id="loader-container"
      className="fullscreen-overlay d-flex flex-column justify-content-center align-items-center"
    >
      <DotLoader color={"aqua"} />
    </div>
  );
}

export default index;
