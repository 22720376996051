import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Telephone, Envelope, Instagram } from "react-bootstrap-icons";
import { useStrapiContext } from "context/StrapiContext";

import "./style.css";

function Footer() {
  const { content, fetchContent } = useStrapiContext();

  useEffect(() => {
    fetchContent(["contactInfo", "companyLogo", "serviceAreas"]);
  }, []);

  let logo2 = content?.companyLogo?.data?.attributes?.logo2.data.attributes.url;

  let serviceAreas = content?.serviceAreas?.data;
  let contact = content?.contactInfo?.data?.attributes;

  return (
    <div
      id="footer"
      className="glass border-0 border-top border-dark shadow w-100 border"
    >
      <Container>
        <Row className="g-0 p-0 m-0">
          <Col
            xs={12}
            lg={4}
            className="p-2 d-flex flex-column justify-content-center align-items-center"
          >
            <Image
              id="footer-logo-title"
              src={logo2}
              loading="lazy"
              width="200"
            ></Image>
            <span className="fw-lighter fs-5 text-light">{contact?.city}</span>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={4}
            className="p-2 fw-lighter text-light d-flex flex-column align-items-center"
          >
            <span className="fs-3">Contact</span>
            <a href={`tel:${contact?.phone}`} className="text-decoration-none">
              <span className="fs-5 text-light">
                <Telephone className="mx-2 text-primary" />
                {contact?.phone}
              </span>
            </a>
            <a
              href={`mailto:${contact?.email}`}
              className="text-decoration-none"
            >
              <span className="fs-5 text-light">
                <Envelope className="mx-2 text-primary" />
                {contact?.email}
              </span>
            </a>
            <a
              href={`https://www.instagram.com/${contact?.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <span className="fs-5 text-light">
                <Instagram className="mx-2 text-primary" />
                {contact?.instagram}
              </span>
            </a>
          </Col>
          <Col
            className="p-2 fw-lighter text-light d-flex flex-column align-items-center"
            xs={12}
            md={6}
            lg={4}
          >
            {serviceAreas &&
              serviceAreas.map((item: any, index: any) => {
                return (
                  <span key={`footer-span-${index}`} className="fs-3">
                    {item?.attributes?.Area}
                  </span>
                );
              })}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
