import React from "react";
import "App.css";
import { Route, Routes } from "react-router-dom";

import About from "pages/About";
import Landing from "pages/Home";
import Services from "pages/Services";
import Clients from "pages/Clients";
import { ModalContextProvider } from "context/ModalContext";
import { StrapiContextProvider } from "context/StrapiContext";

function App() {
  return (
    <StrapiContextProvider>
      <ModalContextProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/About" element={<About />} />
            <Route path="/Clients" element={<Clients />} />
            <Route path="/Services" element={<Services />} />

            {/* Fallback Route for unmatched routes */}
            <Route path="*" element={<Landing />} />
          </Routes>
        </div>
      </ModalContextProvider>
    </StrapiContextProvider>
  );
}

export default App;
